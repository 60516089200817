/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
import { ActionIcon, Box, Checkbox, FocusTrap, Menu, Text, TextInput, Tooltip } from '@mantine/core'
import { createStyles } from '@mantine/emotion'
import { useHover } from '@mantine/hooks'
import { PageDto, Page } from 'api/dto'
import { useNavigate, useParams } from 'react-router-dom'
import { TileProps } from './tile'
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'
import { useKeyPress } from 'hooks/useKeyPress'
import { formatMoment } from 'utils/date'
import { ColorScheme } from 'theme/theme'
import Logo from 'assets/img/ttlogo.svg?react'
import EditIcon from 'assets/icons/edit.svg?react'
import TrashIcon from 'assets/icons/trash.svg?react'
import PencilIcon from 'assets/icons/pencil.svg?react'
import { IconSettings, IconArrowUp, IconArrowDown, IconCopy } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { ImageLoader } from 'components/image/image-loader'
import { useDeletePage, useUpdatePage } from 'api/query/page'
import { useGetProject } from 'api/query/project'
import { GlobalFeature } from 'api/dto/feature'
import isEmpty from 'lodash/isEmpty'

const usePageTileStyles = createStyles(
  (theme, { active, thumbnails, isReady }: { isReady?: boolean; active: boolean; thumbnails: boolean }) => ({
    container: {
      position: 'relative',
      width: '16.1875rem',
    },
    tile: {
      position: 'relative',
      backgroundColor: theme.white,
      maxWidth: '18.3125rem',
      maxHeight: '10.625rem',
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '4px',
      transition: 'all 50ms ease',

      border: active && thumbnails ? `2px solid ${theme.colors.blue[6]}` : 'none',

      ':hover': {
        cursor: thumbnails && isReady ? 'pointer' : undefined,
        border: thumbnails && isReady ? `2px solid ${theme.colors.blue[6]}` : 'none',
      },
    },

    text: {
      cursor: isReady ? 'pointer' : undefined,
      textDecoration: isReady ? 'underline' : undefined,

      ':hover': {
        color: theme.colors[ColorScheme.customGray][2],
      },
    },
  }),
)

interface PageTileProps extends TileProps<Page> {
  textColor?: string
  canEdit?: boolean
  thumbnails?: boolean
  showMenu?: boolean
}

export const PageTile = forwardRef<HTMLDivElement, PageTileProps>(
  (
    { item: page, index, total, textColor, onCheck, canEdit, canNavigate, checked, showMenu, showChangeOrderButtons, thumbnails = true },
    ref,
  ) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { projectId, pageId } = useParams()
    const { hovered, ref: hoverRef } = useHover()
    const { data: project } = useGetProject(projectId)
    const [name, setName] = useState(page.name)
    const [isEditing, setIsEditing] = useState(false)

    const active = useMemo(() => page.id === pageId, [page.id, pageId])
    const { classes } = usePageTileStyles({ thumbnails, active, isReady: page.isInReadyState })

    const escPressed = useKeyPress('Escape')
    const enterPressed = useKeyPress('Enter')

    const { mutateAsync: updatePage } = useUpdatePage()
    const { mutateAsync: deletePageMutation } = useDeletePage()

    const link = useMemo(() => {
      const layerId = page.getOriginalLayerId()
      return `/board/${page.projectId}/pages/${page.id}/layers/${layerId}`
    }, [page])

    const copyPage = useCallback(async () => {
      // TODO: implement copyPage
      // await PageService.copyPage(item.projectId, {
      //   name: `${item.name} ${t('Common.CopyOfItem')}`,
      //   system: item.system,
      //   src: item.srcUntainted,
      //   thumbnailSrc: item.thumbnailSrcUntainted,
      // })
    }, [t, page])

    const updatePageName = useCallback(
      async (name: string) => {
        await updatePage({ projectId: page.projectId, page: { id: page.id, name } as PageDto })
      },
      [updatePage, page],
    )

    const deletePage = useCallback(async () => {
      // TODO permission check

      await deletePageMutation({ projectId: page.projectId, pageId: page.id })
    }, [page, deletePageMutation])

    const moveUp = useCallback(async () => {
      await updatePage({ projectId: page.projectId, page: { ...page, order: page.order - 1 } as PageDto })
    }, [updatePage, page])

    const moveDown = useCallback(async () => {
      await updatePage({ projectId: page.projectId, page: { ...page, order: page.order + 1 } as PageDto })
    }, [updatePage, page])

    useEffect(() => {
      if (!isEditing) {
        return
      }

      if (escPressed || enterPressed) {
        setIsEditing(false)
        void updatePageName(name)
      }
    }, [isEditing, updatePageName, name, escPressed, enterPressed])

    return (
      <Box className={classes.container}>
        <Box
          sx={{
            position: 'absolute',
            top: thumbnails ? 7 : 10,
            left: thumbnails ? 5 : undefined,
            zIndex: 1,
          }}
        >
          {checked && project?.hasAccess(GlobalFeature.EXPORT) && page.isInReadyState && (
            <Checkbox radius="4px" checked={checked?.includes(page.id)} onChange={() => onCheck?.(page)} />
          )}
        </Box>
        {showMenu && page.isInReadyState && (
          <Menu shadow="md" width={270} withinPortal>
            <Menu.Target>
              <ActionIcon
                size="sm"
                variant="filled"
                color="gray"
                sx={{
                  position: 'absolute',
                  top: 5,
                  right: 5,
                  zIndex: 1,
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <IconSettings />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown onClick={(e) => e.stopPropagation() as any}>
              <Menu.Label>
                <Text size="md" sx={(theme) => ({ color: theme.colors[ColorScheme.customGray][2] })}>
                  {page.name}
                </Text>

                <Text size="xs" sx={(theme) => ({ color: theme.colors[ColorScheme.customGray][2] })}>
                  {page.lastModifiedAt && formatMoment(page.lastModifiedAt)}
                </Text>
              </Menu.Label>
              <Menu.Item leftSection={<EditIcon width={17} height={17} />} onClick={() => navigate(link)}>
                {t('Page.Menu.OpenPage')}
              </Menu.Item>
              <Menu.Item disabled leftSection={<IconCopy stroke="#adb5bd" width={17} height={17} />} onClick={() => copyPage()}>
                {t('Page.Menu.MakeAnEmptyCopy')}
              </Menu.Item>
              <Menu.Item
                disabled={!project?.hasAccess(GlobalFeature.DELETE_PAGE)}
                leftSection={
                  <TrashIcon fill={!project?.hasAccess(GlobalFeature.DELETE_PAGE) ? '#adb5bd' : undefined} width={17} height={17} />
                }
                onClick={() => deletePage()}
              >
                {t('Page.Menu.DeletePage')}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        )}

        <Box ref={ref} className={classes.tile} onClick={() => canNavigate && navigate(link)}>
          {thumbnails && (
            <ImageLoader
              style={{ borderRadius: 4, maxWidth: '258px', maxHeight: '168px', height: '168px', padding: '5px' }}
              src={page.thumbnailImage}
              alt="thumbnail"
              reloadTime={2000}
              isLoading={!page.isInReadyState}
            />
          )}

          {showChangeOrderButtons && (
            <Box
              sx={{
                position: 'absolute',
                display: 'flex',
                bottom: thumbnails ? 5 : -28,
                right: 5,
                zIndex: 1,
              }}
            >
              {index !== 0 && (
                <ActionIcon
                  size="sm"
                  radius="xs"
                  color="gray"
                  variant="filled"
                  onClick={async (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    await moveUp()
                  }}
                >
                  <IconArrowUp />
                </ActionIcon>
              )}
              {index !== total - 1 && (
                <ActionIcon
                  ml={5}
                  size="sm"
                  radius="xs"
                  color="gray"
                  variant="filled"
                  onClick={async (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    await moveDown()
                  }}
                >
                  <IconArrowDown />
                </ActionIcon>
              )}
            </Box>
          )}
        </Box>

        <Box ml={!thumbnails && checked ? 30 : 0} mt={7} display="flex">
          {!isEditing && (
            <Box display="flex" sx={{ alignItems: 'baseline' }}>
              {page.hasTakeoff && (
                <Tooltip label={t('Page.HasTakeoff')} withArrow>
                  <Box mr={10}>
                    <Logo width={15} height={15} style={{ fill: 'rgb(35,149,255)' }} />
                  </Box>
                </Tooltip>
              )}
              <Box>
                <Text
                  fz={16}
                  maw={200}
                  lh="19px"
                  className={canEdit ? classes.text : undefined}
                  sx={(theme) => ({
                    color: active && !thumbnails ? 'black' : textColor || 'black',
                    textDecorationStyle: hovered ? 'dotted' : 'unset',
                    wordBreak: 'break-all',
                  })}
                  onClick={() => (page.isInReadyState ? navigate(link) : undefined)}
                >
                  {page.name}
                </Text>
              </Box>

              {canEdit && page.isInReadyState && (
                <Box ref={hoverRef}>
                  <PencilIcon
                    onClick={() => setIsEditing(true)}
                    fill={active && !thumbnails ? 'black' : textColor || 'black'}
                    width={12}
                    height={12}
                    style={{ marginLeft: '10px', marginTop: '-2px', cursor: 'pointer' }}
                  />
                </Box>
              )}
            </Box>
          )}
          {isEditing && (
            <Box w="100%">
              <FocusTrap active>
                <TextInput
                  autoFocus
                  size="sm"
                  variant=""
                  sx={{
                    width: '100%',
                    lineHeight: '19px',
                    color: textColor || 'black',
                    backgroundColor: 'white',
                    borderRadius: 4,
                    padding: 0,
                    margin: 0,
                    boxShadow: 'none',

                    input: {
                      fontSize: '16px',
                    },
                  }}
                  defaultValue={name}
                  onChange={(e) => setName(e.target.value)}
                  onBlur={async (e) => {
                    if (isEmpty(e.target.value)) {
                      setName(page.name)
                      setIsEditing(false)
                      return
                    }

                    if (e.target.value === page.name) {
                      setIsEditing(false)
                      return
                    }

                    setIsEditing(false)
                    await updatePageName(e.target.value)
                  }}
                />
              </FocusTrap>
            </Box>
          )}
        </Box>
      </Box>
    )
  },
)
